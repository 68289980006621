import React from 'react'
import { useEffect } from 'react'

import { Fragment, useState } from 'react'
import { Dialog, Menu, Transition } from '@headlessui/react'
import Loading from '../components/Loading'
import SearchBar from '../components/SearchBar';
import Header from '../components/Header'
// import Map from '../components/Map'
import MapWithWrapper from '../components/MapWithWrapper'
import LocationEntryList from '../components/LocationEntryList'
import useStore from "../store";
import Footer from "../components/Footer";
import { Bars4Icon, MapPinIcon } from '@heroicons/react/24/outline'


function classNames(...classes) {
    return classes.filter(Boolean).join(' ')
}

export default function Example() {
    const fetchLocations = useStore((state) => state.fetchLocations)
    const updateFilterdLocations = useStore((state) => state.updateFilterdLocations)
    const tags = useStore((state) => state.tags)
    const fetchTags = useStore((state) => state.fetchTags)
    const locations = useStore((state) => state.locations)
    const [isPageLoading, setIsPageLoading] = useState(true);
    const [mapIsOpen, setmapIsOpen] = useState(true);


    useEffect(() => {
        if(locations.length === 0){
            fetchLocations().then(() => {
                updateFilterdLocations();
                setIsPageLoading(false);
            })
        } else {
            updateFilterdLocations();
            setIsPageLoading(false);
        }
        if(tags.length === 0) fetchTags()
    }, [])

    //write a function that sets the height of map to ser on click :
    const closeMap = () => {
        setmapIsOpen(!mapIsOpen)
    }


    return (
        <>
        {isPageLoading && (
        <Loading></Loading>
        )}
      {!isPageLoading && (
          <>
          <SearchBar></SearchBar>
            <div>
                <div className="flex flex-col flex-1 w-full min-h-screen">
                   <Header includeFilters={true}></Header>
                    <div className='flex w-full border-prime-900 z-10 relative'>
                        <div className={
                            classNames(
                            "w-full md:w-[500px] lg:w-[700px] xl:w-[900px] h-full bg-white min-h-screen pb-12 mt-[64px]")}>
                            <LocationEntryList></LocationEntryList>

                        </div>

                        <main className={
                            classNames(
                            mapIsOpen ? "left-0" : "-left-full" ,
                            'w-full mt-[64px] md:mt-0 fixed md:w-[calc(100%-500px)] lg:w-[calc(100%-700px)] xl:w-[calc(100%-900px)] h-screen md:sticky top-0 right-0 z-20 md:pt-[90px] transition-all duration-500 ')}>
                            {/* <Map></Map> */}
                            <MapWithWrapper onClickMarker={closeMap}></MapWithWrapper>

                            <div className='w-full text-center flex justify-end fixed bottom-0 right-0 md:hidden'>
                              <div className='rounded-full h-40 w-40 bg-orange-400 -mr-16 -mb-16 relative' onClick={closeMap}>
                                {mapIsOpen ? 
                                  <Bars4Icon className='w-10 h-10 text-white absolute top-[25%] left-[25%]' /> : <MapPinIcon className='w-10 h-10 text-white absolute top-[25%] left-[25%]' />
                                }
                              </div>
                             
                        </div>
                        </main>

                    </div>
                </div>
            </div>
            <div className='z-30'>
            <Footer></Footer>
            </div>
            </>
            )}
        </>
    )
}