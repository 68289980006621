import Header from '../components/Header'
import Footer from "../components/Footer";
import Impressum from '../components/Impressum'
import ScrollToTop from '../components/ScrollToTop';

export default function ImpressumPage() {
  return (
    <>
      <div>
        <div className="flex flex-col flex-1 w-full min-h-screen">
          <Header></Header>
          <div className='flex w-full mt-[64px] border-prime-900 z-10 relative max-w-5xl mx-auto p-5'>
            <Impressum></Impressum>
          </div>
        </div>
      </div>
      <div className='z-30'>
          <Footer></Footer>
      </div>
      <ScrollToTop />
    </>
  )
}
