import React from 'react'
import { useEffect } from 'react'

import { useParams } from 'react-router';
import { useNavigate } from 'react-router-dom';

import { Fragment, useState } from 'react'
import { Dialog, Menu, Transition } from '@headlessui/react'

import Header from '../components/Header'

import LocationEntryList from '../components/LocationEntryList'
import useStore from "../store";
import Footer from "../components/Footer";
import LocationDetail from '../components/LocationDetail'
import Loading from '../components/Loading';

function classNames(...classes) {
    return classes.filter(Boolean).join(' ')
}

export default function LocationDetailPage() {
    const navigateTo = useNavigate();
    const fetchLocationById = useStore((state) => state.fetchLocationById)
    const fetchEventById = useStore((state) => state.fetchEventById)

    const { id } = useParams();
    const currentLocation = useStore((state) => state.currentLocation)
    const [isPageLoading, setIsPageLoading] = useState(true);


    useEffect(() => {


        fetchLocationById(id).then(async (data) => {
            if(data.events){
                //loop over all events and fetch each event
                const promises = data.events.map((event) => {
                  fetchEventById(event.id)
                })

                // wait until all events fetched
                await Promise.all(promises);

                // set loading
                setIsPageLoading(false)
            } else {
              setIsPageLoading(false)
            }
        }
        )
    }, [])

    if (isPageLoading) return <Loading />
    return (
        <>
        
            <div>
                <div className="flex flex-col flex-1 w-full min-h-screen">
                    <Header></Header>
                    <div className='flex w-full mt-[64px] border-prime-900 z-10 relative'>
                        <LocationDetail location={currentLocation}></LocationDetail>
                    </div>
                </div>
            </div>
            <div className='z-30'>
                <Footer></Footer>
            </div>
        </>
    )
}