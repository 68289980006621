import React, { useEffect } from 'react'
import { Fragment, useState } from 'react'
import useStore from "../../store";

function MarkerIcon(props) {
	const tags = useStore((state) => state.localTags)
	console.log(props.location);
	const fill = props.fill || 'currentColor';
	const secondaryfill = props.secondaryfill || 'currentColor';
	const fillOpacity = props.fillOpacity || '0.2';
	const title = props.title || "trash can";
	const classProps = props.classProps || '';
	const strokeWidth = props.strokeWidth || '3';

	const findTagStylesByTagName = () => {
		let tag = tags.find((tag) => tag.name === props.location.tags[0].name);
		console.log(tag);

		return tag ? tag : {};
	};

	return (

		<>
			<span className='hidden fill-black stroke-black text-black fill-lime-500 text-lime-500 text-trennt_gray fill-trennt_gray text-trennt_green fill-trennt_green fill-trennt_brown text-trennt_brown text-trennt_blue fill-trennt_blue text-trennt_turcouse fill-trennt_turcouse text-trennt_yellow fill-trennt_yellow fill-prime-900'></span>
			{props.location?.tags[0] ? (
				<>
					<div className='relative'>
						<svg className={classProps + " absolute stroke-1"} fill="none" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
							<path className={'stroke-'
								+( props.isActive? "black" : findTagStylesByTagName().color) + ' text-'
								+ ( props.isActive? "black" : findTagStylesByTagName().color) + ' fill-'
								+ ( props.isActive? "black" : findTagStylesByTagName().color)}
								fillOpacity={fillOpacity} strokeOpacity={fillOpacity} strokeLinecap="round" strokeLinejoin="round" d="M17.657 16.657L13.414 20.9a1.998 1.998 0 01-2.827 0l-4.244-4.243a8 8 0 1111.314 0z" />
						</svg>
						<div className='pt-2 py-2 pb-1 my-auto text-center cursor-pointer items-center justify-center '>
							<div className='relative w-[14px] h-[14px] text-white m-auto'>
								{findTagStylesByTagName().icon}</div>
						</div>
					</div>
					
					{/* <div className={"pt-2 py-2 pb-1 my-auto text-center cursor-pointer items-center justify-center fill-b-4  " +
								 			' border-b-4 border-'
										 + findTagStylesByTagName().color + ' bg-'
										 + findTagStylesByTagName().color + 'opacity-10 stroke-'
										 + findTagStylesByTagName().color + ' text-'
										 + findTagStylesByTagName().color + ' fill-'
										 + findTagStylesByTagName().color }
								>
								 <div className='w-7 h-7 mx-auto '>
										 {findTagStylesByTagName().icon}
								 </div>
								 
						 </div> */}
				</>
			) : (

				<svg className={classProps + " stroke-1"} fill="none" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
					<path stroke={fill} fill={fill} fillOpacity={fillOpacity} strokeOpacity={fillOpacity} strokeLinecap="round" strokeLinejoin="round" d="M17.657 16.657L13.414 20.9a1.998 1.998 0 01-2.827 0l-4.244-4.243a8 8 0 1111.314 0z" />
					<path stroke={secondaryfill} fill={secondaryfill} strokeLinecap="round" strokeLinejoin="round" d="M15 11a3 3 0 11-6 0 3 3 0 016 0z" />
				</svg>
			)}
		</>


	);
};

export default MarkerIcon;