import React from 'react';

function Present(props) {
	const fill = props.fill || 'currentColor';
	const secondaryfill = props.secondaryfill || fill;
	const title = props.title || "trash can";
	const classProps = props.classProps  || '';
	const strokeWidth = props.strokeWidth || '3';

	return (
		<svg className=''viewBox="0 0 64 64" xmlns="http://www.w3.org/2000/svg">
	
	<g fill={secondaryfill} stroke={secondaryfill}  strokeLinecap="square" strokeLinejoin="miter" strokeMiterlimit="10" >
		<path d="M56,30V54a5,5,0,0,1-5,5H13a5,5,0,0,1-5-5V30" fill="none" stroke={fill}/>
		<path d="M14,8a6,6,0,0,1,6-6c8.875,0,12,12,12,12H20A6,6,0,0,1,14,8Z" fill="none"/>
		<path d="M50,8a6,6,0,0,0-6-6C35.125,2,32,14,32,14H44A6,6,0,0,0,50,8Z" fill="none"/>
		<rect height="10" width="60" fill="none"stroke={fill} x="2" y="14"/>
		<polyline fill="none" points="38 14 38 59 26 59 26 14"/>
	</g>
</svg>
	);
};

export default Present;