import React from 'react';

function Restaurant(props) {
	const fill = props.fill || 'currentColor';
	const secondaryfill = props.secondaryfill || fill;
	const title = props.title || "trash can";
	const classProps = props.classProps  || '';
	const strokeWidth = props.strokeWidth || '3';

	return (
		<svg viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
	<g fill="none" >
		<path d="M16 6v8h3v8h2V2c-2.76 0-5 2.24-5 4zm-5 3H9V2H7v7H5V2H3v7c0 2.21 1.79 4 4 4v9h2v-9c2.21 0 4-1.79 4-4V2h-2v7z" stroke={secondaryfill} strokeWidth="5%" />
	</g>
</svg>
	);
};

export default Restaurant;

{/* <g>
		<path fill={fill} d="M13.463,2.353c-1.508,1.486-2.412,3.655,0.745,16.652c1.891,7.786,16.652,51.198,21.682,63.67
			c10.235,25.374,16.786,24.537,20.539,25.02c1.833,0.236,3.616-0.194,5.157-1.247c4.197-2.864,5.597-3.699,7.218-4.667
			c0.569-0.339,1.176-0.702,1.936-1.169c4.537,7.434,10.138,18.967,15.576,30.163c7.317,15.067,14.884,30.648,20.93,38.71
			c3.845,5.125,8.003,6.633,10.815,6.994c5.382,0.692,10.948-2.192,14.183-7.348c3.281-5.232,3.392-11.437,0.288-16.19
			c-6.389-9.79-99.916-132.93-113.936-149.832C16.65,0.761,14.331,1.497,13.463,2.353z M126.526,165.543
			c-1.839,2.933-4.824,4.597-7.604,4.239c-2.182-0.28-4.353-1.785-6.275-4.349c-5.658-7.543-13.08-22.827-20.259-37.608
			c-6.558-13.503-12.752-26.258-17.819-33.75c-0.565-0.835-1.438-1.337-2.366-1.457c-0.763-0.098-1.564,0.063-2.26,0.513
			c-2.266,1.463-7.795,4.76-12.163,7.741c-0.2,0.137-0.317,0.15-0.49,0.129c-2.315,1.198-2.462-0.561-6.369-5.864
			c-6.293-8.542-24.063-58.716-29.203-75.196c-1.994-6.391,0.13-2.867,1.692-0.857c16.886,21.724,97.263,128.037,103.47,137.548
			C128.849,159.646,127.988,163.212,126.526,165.543z"/>
		<path fill={fill} d="M176.854,35.658c-1.299-1.338-3.436-1.37-4.772-0.071c-8.615,8.358-16.65,16.508-23.106,23.057
			c-1.649,1.674-3.16,3.206-4.528,4.587l-6.365-6.37l26.897-26.098c1.338-1.298,1.369-3.436,0.071-4.773s-3.434-1.37-4.772-0.071
			l-26.969,26.167l-5.992-5.997l27.058-26.254c1.338-1.299,1.37-3.436,0.072-4.772c-1.298-1.338-3.436-1.37-4.772-0.072
			l-27.129,26.323l-5.837-5.842l29.318-28.447c1.338-1.298,1.37-3.435,0.071-4.771c-1.298-1.339-3.434-1.37-4.771-0.072
			l-33.739,32.737c-8.623,8.366-7.957,20.653,1.438,31.984l-19.189,18.9c-1.338,1.298-1.37,3.435-0.072,4.771
			c0.522,0.538,1.181,0.865,1.867,0.979c1.021,0.17,2.106-0.131,2.906-0.907l19.203-18.914c3.781,3.148,8.251,5.231,12.947,6.011
			c0.604,0.102,1.215,0.18,1.826,0.236c6.129,0.57,11.745-1.144,15.412-4.702c2.342-2.271,5.657-5.635,9.856-9.894
			c6.433-6.524,14.438-14.645,23-22.951C178.119,39.133,178.151,36.996,176.854,35.658z M129.138,71.257
			c-4.737-0.44-9.29-2.684-12.812-6.314c-6.9-7.112-11.598-17.279-4.449-24.758L139.669,68c-0.152,0.148-0.3,0.293-0.444,0.433
			C137.008,70.582,133.238,71.639,129.138,71.257z"/>
		<path fill={fill} d="M74.588,116.551c-1.394-1.239-3.527-1.113-4.765,0.281c-13.581,15.281-38.226,42.275-46.542,49.651
			c-4.134,3.667-10.646,2.188-14.133-1.496c-3.647-3.852-3.097-8.73,1.509-13.388c11.695-11.819,28.923-25.353,41.918-35.152
			c1.488-1.122,1.785-3.238,0.663-4.727s-3.238-1.785-4.727-0.663c-13.162,9.926-30.649,23.663-42.652,35.794
			c-8.742,8.837-6.332,17.792-1.612,22.776c2.667,2.815,6.444,4.841,10.456,5.507c4.464,0.741,9.219-0.199,13.057-3.604
			c9.61-8.523,37.265-39.141,47.109-50.218C76.107,119.922,75.981,117.787,74.588,116.551z"/>
	</g> */}