import React, { useEffect } from 'react'
import { Fragment, useState } from 'react'
import useStore from "../store";

export default function Filters(props) {
    const activeTags = useStore((state) => state.activeTags)
    const addActiveTag = useStore((state) => state.addActiveTag)
    const removeActiveTag = useStore((state) => state.removeActiveTag)
    const localTags = useStore((state) => state.localTags)
    const tags = useStore((state) => state.tags)

    const [selectedLocalFilters, setSelectedLocalFilters] = useState([]);

    const handleFilterClick = (filter) => {
        if (selectedLocalFilters.includes(filter)) {
            removeActiveTag(filter.name)
        } else {
            addActiveTag(filter.name)
        }
    }

    useEffect(() => {
        let newActiveTags = []
        console.log("WTF is going on", activeTags, newActiveTags)
        // for each active tag find the tag in the local tags array
        // and add it to the selected local filters
        console.log("activeTags", activeTags)
        activeTags.forEach(tag => {
            const localTag = localTags.find(localTag => localTag.name === tag.name)
            newActiveTags.push(localTag)
        }
        )
        setSelectedLocalFilters(newActiveTags)
    }, [activeTags])

    return (
        <>
            <span 
            className='hidden border-lime-500 text-lime-500 text-trennt_gray border-trennt_gray text-trennt_green border-trennt_green border-trennt_brown text-trennt_brown text-trennt_blue border-trennt_blue text-trennt_turcouse border-trennt_turcouse text-trennt_yellow border-trennt_yellow'></span>
            {localTags.map((localTag, index) => (
                <>
                    {tags.filter(e => e.name === localTag.name).length > 0 && (

                        <div key={index} className={"pt-2 py-2 pb-1 my-auto text-center cursor-pointer items-center justify-center border-b-4  " +
                            (selectedLocalFilters.includes(localTag) ? ' border-b-4 border-'
                                + localTag.color + ' bg-'
                                + localTag.color + 'opacity-10 stroke-'
                                + localTag.color + ' text-'
                                + localTag.color + ' fill-'
                                + localTag.color : 'border-white text-gray-500')}
                            onClick={() => handleFilterClick(localTag)}>
                            <div className='w-7 h-7 mx-auto stroke-[4px] '>
                                {localTag.icon}
                            </div>
                            <small className='sm:block text-xs'>{localTag.name}</small>
                        </div>
                    )}
                </>
            ))}
        </>
    )
}
