import React from 'react';

function MarkerIcon(props) {
	const fill = props.fill || 'currentColor';
	const secondaryfill = props.secondaryfill || fill;
	const title = props.title || "trash can";
	const classProps = props.classProps  || '';
	const strokeWidth = props.strokeWidth || '3';

	return (
		<svg className={classProps} fill="none" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
	 <path  stroke={fill} fill={fill}  fillOpacity="0.2" strokeOpacity="0.8"	strokeLinecap="round" strokeLinejoin="round" d="M17.657 16.657L13.414 20.9a1.998 1.998 0 01-2.827 0l-4.244-4.243a8 8 0 1111.314 0z" />
  <path   stroke={fill} fill={fill} opacity=".9" strokeLinecap="round" strokeLinejoin="round" d="M15 11a3 3 0 11-6 0 3 3 0 016 0z" />
</svg>
	);
};

export default MarkerIcon;