import React, { useCallback, useState } from 'react'
import FilterBar from './FiltersNavigation'
import LocationEventSwitch from './LocationEventSwitch'
import { Link } from 'react-router-dom';
import SuggestLocationEntry from './SuggestLocationEntry'
import { FunnelIcon  } from '@heroicons/react/24/outline'

export default function Header(props) {
    const [showMobileFilter, setShowMobileFilter] = useState();

    const handleTogggleMobileFilter = useCallback(() => {
      setShowMobileFilter(value => !value)
    }, [])
  
    return (
        <div className='fixed w-full z-40'>
            <div className="flex-shrink-0 flex h-16 bg-white relative z-40">

                <div className="flex-1 px-4 flex justify-between overflow-hidden border-b ">

                    <div className="flex">
                        <div className="flex-shrink-0 flex items-center px-4">
                        <Link
                          to="/"
                        >
                            <img
                                className="w-24 md:w-32 h-auto"
                                src="/img/trenntmap_logo_4c_pos.jpg"
                                alt="Workflow"
                            />
                            </Link>
                        </div>

                    </div>

                    <p className="my-auto cursive-font lg:text-2xl hidden md:block">Gemeinsam für ein nachhaltigeres Berlin</p>
                    <div className="ml-4 flex items-center md:ml-6 gap-3">
                        <div className='md:hidden'><LocationEventSwitch></LocationEventSwitch></div>
                        <div className='md:hidden'><FunnelIcon className='w-6 h-6' onClick={handleTogggleMobileFilter} /></div>
                        <SuggestLocationEntry></SuggestLocationEntry>
                    </div>
                </div>
            </div>
            {(props.includeFilters) && (
              <div className={`w-full bg-white md:hidden transition-all duration-500 left-0 grid grid-cols-5 gap-2 ${showMobileFilter ? 'top-[64px]' : '-translate-y-full'}`}>
                <FilterBar></FilterBar>
              </div>
            )}
            {(props.includeFilters) && (
            <div className='hidden w-full bg-white shadow-md z-40 md:flex overflow-x-auto' >
                <div className='my-auto ml-2 mr-2 md:ml-12 md:mr-6'>
                    <LocationEventSwitch></LocationEventSwitch>
                </div>
                <div className='flex justify-evenly space-x-12 border-l-2 '>
                    <FilterBar></FilterBar>
                </div>
            </div>
            )}
        </div>
    )
}
