import { Link } from 'react-router-dom';

export default function Impressum(props) {
  return (
    <div className='w-full'>
      <section aria-labelledby="quick-links-title">
        <div className="rounded-lg bg-white overflow-hidden shadow divide-y ">
          <div className='p-6'>
            <h3 className='text-3xl text-orange-500'>Impressum</h3>

            <div className=''>
              <div className=''>
                <div className="mb-3 mt-6">
                  <p className="mt-1 text-md text-gray-600 whitespace-pre-wrap font-bold">BR Berlin Recycling GmbH</p>
                  <p className="mt-1 text-md text-gray-600 whitespace-pre-wrap">Monumentenstraße 14</p>
                  <p className="mt-1 text-md text-gray-600 whitespace-pre-wrap">10829 Berlin</p>
                </div>
                <div className="mb-3">
                  <p className="mt-1 text-md text-gray-600 whitespace-pre-wrap">
                    <strong>Telefon:</strong>{" "}
                    <a target='_blank' className="text-orange-500" href="tel:(030) 60 97 20 0" rel="noreferrer">
                      (030) 60 97 20 0
                    </a>
                  </p>
                  <p className="mt-1 text-md text-gray-600 whitespace-pre-wrap">
                    <strong>Telefax:</strong> (030) 60 97 20 10
                  </p>
                  <p className="mt-1 text-md text-gray-600 whitespace-pre-wrap">
                    <strong>Email:</strong>{" "}
                    <a className="text-orange-500" href="mailto:info@berlin-recycling.de" rel="noreferrer">
                      info[at]berlin-recycling.de
                    </a>
                  </p>
                  <p className="mt-1 text-md text-gray-600 whitespace-pre-wrap">
                    Sie erreichen uns von Montag bis Freitag von 08:00 bis 18:00 Uhr. Außer an Feiertagen.
                  </p>
                  <p className="mt-1 text-md text-gray-600 whitespace-pre-wrap">
                    Unser Kontaktformular finden Sie auf der <a target='_blank' className="text-orange-500" href="https://www.berlin-recycling.de/kontakt" rel="noreferrer">folgenden Seite</a>.
                  </p>
                </div>
              </div>
            </div>
           
            

            <div className="space-y-3">
              <p className="mt-1 text-md text-gray-600 whitespace-pre-wrap">
                <div className="font-bold">Geschäftsführung:</div>
                <div>Sascha Förster, Bianka Rieder</div>
              </p>
              <p className="mt-1 text-md text-gray-600 whitespace-pre-wrap">
                <div className="font-bold">Aufsichtsratsvorsitzende:</div>
                <div>Stephanie Otto</div>
              </p>
              <p className="mt-1 text-md text-gray-600 whitespace-pre-wrap">
                <div className="font-bold">Eintragung im Handelsregister:</div>
                <div>Amtsgericht Berlin-Charlottenburg HRB 75433</div>
              </p>
              <p className="mt-1 text-md text-gray-600 whitespace-pre-wrap">
                <div className="font-bold">Umsatzsteuer-Identifikationsnummer gemäß §27 a Umsatzsteuergesetz:</div>
                <div>DE211842465</div>
              </p>
              <p className="mt-1 text-md text-gray-600 whitespace-pre-wrap">
                <div className="font-bold">Sitz der Gesellschaft:</div>
                <div>Berlin</div>
                <div>Berlin Recycling ist ein Unternehmen der <a target="_self" className="text-orange-500" href="https://www.bsr.de/" rel="noreferrer">BSR-Gruppe</a>.</div>
              </p>
              <p className="mt-1 text-md text-gray-600 whitespace-pre-wrap">
                <div className="font-bold">Redaktionelle Verantwortung:</div>
                <div>Jessica Kaiser & Stefan Suck, BR Berlin Recycling GmbH</div>
              </p>

              <p className="mt-1 text-md text-gray-600 whitespace-pre-wrap">
                <div className="font-bold">Haftung für Inhalte</div>
                <div>
                  Als Diensteanbieter sind wir gemäß § 7 Abs.1 TMG für eigene Inhalte auf diesen Seiten nach den allgemeinen Gesetzen verantwortlich. Nach §§ 8 bis 10 TMG sind wir als Diensteanbieter jedoch nicht verpflichtet, übermittelte oder gespeicherte fremde Informationen zu überwachen oder nach Umständen zu forschen, die auf eine rechtswidrige Tätigkeit hinweisen.
                  <br />
                  Verpflichtungen zur Entfernung oder Sperrung der Nutzung von Informationen nach den allgemeinen Gesetzen bleiben hiervon unberührt. Eine diesbezügliche Haftung ist jedoch erst ab dem Zeitpunkt der Kenntnis einer konkreten Rechtsverletzung möglich. Bei Bekanntwerden von entsprechenden Rechtsverletzungen werden wir diese Inhalte umgehend entfernen.
                </div>
              </p>
              <p className="mt-1 text-md text-gray-600 whitespace-pre-wrap">
                <div className="font-bold">Haftung für Links</div>
                <div>
                  Unser Angebot enthält Links zu externen Websites Dritter, auf deren Inhalte wir keinen Einfluss haben. Deshalb können wir für diese fremden Inhalte auch keine Gewähr übernehmen. Für die Inhalte der verlinkten Seiten ist stets der jeweilige Anbieter oder Betreiber der Seiten verantwortlich. Die verlinkten Seiten wurden zum Zeitpunkt der Verlinkung auf mögliche Rechtsverstöße überprüft. Rechtswidrige Inhalte waren zum Zeitpunkt der Verlinkung nicht erkennbar.
                  <br />
                  Eine permanente inhaltliche Kontrolle der verlinkten Seiten ist jedoch ohne konkrete Anhaltspunkte einer Rechtsverletzung nicht zumutbar. Bei Bekanntwerden von Rechtsverletzungen werden wir derartige Links umgehend entfernen.
                </div>
              </p>
              <p className="mt-1 text-md text-gray-600 whitespace-pre-wrap">
                <div className="font-bold">Urheberrecht</div>
                <div>
                  Die durch die Seitenbetreiber erstellten Inhalte und Werke auf diesen Seiten unterliegen dem deutschen Urheberrecht. Die Vervielfältigung, Bearbeitung, Verbreitung und jede Art der Verwertung außerhalb der Grenzen des Urheberrechtes bedürfen der schriftlichen Zustimmung des jeweiligen Autors bzw. Erstellers. Downloads und Kopien dieser Seite sind nur für den privaten, nicht kommerziellen Gebrauch gestattet.
                  <br />
                  Soweit die Inhalte auf dieser Seite nicht vom Betreiber erstellt wurden, werden die Urheberrechte Dritter beachtet. Insbesondere werden Inhalte Dritter als solche gekennzeichnet. Sollten Sie trotzdem auf eine Urheberrechtsverletzung aufmerksam werden, bitten wir um einen entsprechenden Hinweis. Bekanntwerden von Rechtsverletzungen werden wir derartige Inhalte umgehend entfernen.
                </div>
              </p>
              <p className="mt-1 text-md text-gray-600 whitespace-pre-wrap">
                <div className="font-bold">Datenschutzerklärung</div>
                <div>Unsere Datenschutzerklärung finden Sie <Link className="text-orange-500" to="/datenschutzerklaerung">hier</Link>.</div>
              </p>
              <p className="mt-1 text-md text-gray-600 whitespace-pre-wrap">
                <div className="font-bold">EU-Streitschlichtungs-Plattform</div>
                <div>Die Europäische Kommission stellt eine Plattform zur Online-Streitbeilegung (OS) bereit. Die Plattform finden Sie unter <a className="text-orange-500" target="_self" href="https://webgate.ec.europa.eu/odr/" rel="noreferrer">https://webgate.ec.europa.eu/odr/</a>.</div>
              </p>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}
