import React from 'react';

function Checkroom(props) {
	const fill = props.fill || 'currentColor';
	const secondaryfill = props.secondaryfill || fill;
	const title = props.title || "trash can";
	const classProps = props.classProps  || '';
	const strokeWidth = props.strokeWidth || '3';
	return (
		<svg className={classProps} viewBox="0 0 495 495"    xmlns="http://www.w3.org/2000/svg">
<g>
	<path fill={fill} d="M64.795,388.251h365.41c13.672,0,24.795-11.123,24.795-24.794h39.998
		c0-0.029,0.002-0.058,0.002-0.087c0-26.411-15.794-49.95-40.236-59.967L267.5,226.656v-19.895
		c29.683-8.671,51.431-36.135,51.431-68.582c0-39.387-32.044-71.431-71.431-71.431s-71.431,32.043-71.431,71.431h40
		c0-17.331,14.1-31.431,31.431-31.431s31.431,14.1,31.431,31.431s-14.1,31.431-31.431,31.431h-20v57.045L40.236,303.402
		C15.794,313.42,0,336.958,0,363.37c0,0.029,0.002,0.058,0.002,0.087H40C40,377.128,51.123,388.251,64.795,388.251z M55.404,340.415
		L247.5,261.688l192.096,78.727C448.953,344.25,455,353.26,455,363.37c0,13.672-11.123,24.794-24.795,24.794H64.795
		C51.123,388.164,40,377.042,40,363.37C40,353.26,46.047,344.25,55.404,340.415z"/>
	<path fill={fill} d="M455,363.457c0,13.672-11.123,24.794-24.795,24.794H64.795C51.123,388.251,40,377.128,40,363.457H0
		c0,35.728,29.066,64.794,64.795,64.794h365.41c35.729,0,64.795-29.066,64.795-64.794H455z"/>
</g>
</svg>
	);
};

export default Checkroom;