import React from 'react';

function School(props) {
	const fill = props.fill || 'currentColor';
	const secondaryfill = props.secondaryfill || fill;
	const title = props.title || "trash can";
	const classProps = props.classProps  || '';
	const strokeWidth = props.strokeWidth || '3';
	return (
		<svg viewBox="0 0 477.005 477.005" xmlns="http://www.w3.org/2000/svg" clas>
	<g>
	<path
	 fill={fill}  stroke={fill} d="M326.86,353.255c0,7.281-34.683,28.345-100.874,28.345c-66.17,0-100.845-21.064-100.845-28.345v-73.347l-37.788-20.547
		v93.894c0,43.415,69.74,66.132,138.633,66.132c68.909,0,138.662-22.717,138.662-66.132v-93.894l-37.788,20.547V353.255z"/>
	<path
	 fill={fill}  stroke={fill} d="M455.31,374.32l-22.298-71.064c5.38-4.52,8.867-11.201,8.867-18.773c0-10.73-6.907-19.763-16.485-23.145V194.06
		l16.724-9.092c6.085-3.3,9.871-9.675,9.871-16.597c0-6.921-3.786-13.289-9.871-16.596L235.032,39.145
		c-5.641-3.068-12.428-3.068-18.056,0L9.87,151.775C3.786,155.083,0,161.45,0,168.371c0,6.922,3.786,13.297,9.87,16.605
		l207.106,112.607c2.813,1.527,5.919,2.291,9.024,2.291c3.105,0,6.21-0.764,9.024-2.298l174.071-94.649v58.41
		c-9.578,3.374-16.477,12.414-16.477,23.145c0,7.572,3.488,14.268,8.867,18.788l-22.29,71.05c-1.923,6.112-0.816,12.78,2.977,17.951
		c3.801,5.17,9.832,8.223,16.246,8.223h37.667c6.412,0,12.444-3.053,16.237-8.223C456.117,387.106,457.226,380.44,455.31,374.32z
		 M226.001,259.474L58.448,168.371l167.552-91.117l167.538,91.117L226.001,259.474z"/>
</g>
</svg>
	);
};

export default School;

{/* <g>
	<path  fill={"none"}  stroke={fill} d="M7.075,204.297l71.191,34.293v57.087c0,4.801,2.75,9.178,7.075,11.262l136.194,65.605c1.692,0.814,3.546,1.237,5.425,1.237
		h22.886c1.879,0,3.732-0.423,5.426-1.237l135.817-65.425c2.633-1.27,4.738-3.42,5.95-6.079c0.171-0.375,0.334-0.754,0.497-1.134
		c0.663-1.552,1.005-3.223,1.005-4.91v-56.407l19.419-9.354v77.412c-4.202,2.801-7.441,6.998-9.01,12.109l-11.049,36.002
		c-1.361,4.44-1.324,9.124,0.109,13.542l10.246,31.578c2.922,9.007,11.245,15.254,20.709,15.545
		c0.063,0.002,0.125,0.003,0.188,0.004l0.312,0.005c0.065,0.001,0.13,0.001,0.196,0.001c9.304,0,17.52-5.594,20.931-14.253
		l11.847-30.072c1.828-4.637,2.059-9.824,0.65-14.605L452.043,319c-1.539-5.229-4.806-9.522-9.083-12.368v-89.438l26.97-12.992
		c4.325-2.084,7.075-6.46,7.075-11.262v-9.955c0-4.801-2.75-9.178-7.075-11.262L243.827,62.81c-3.429-1.651-7.422-1.651-10.85,0
		L7.075,171.628C2.75,173.711,0,178.088,0,182.89v10.146C0,197.837,2.75,202.213,7.075,204.297z M429.886,385.539l-7.853-24.202
		l8.376-27.295l8.456,28.704L429.886,385.539z M373.54,287.824l-126.548,60.959h-17.179l-126.547-60.959v-37.19l129.712,62.483
		c1.714,0.826,3.569,1.238,5.425,1.238s3.71-0.412,5.425-1.238l129.713-62.482L373.54,287.824L373.54,287.824z M238.402,87.946
		l207.632,100.017l-63.763,30.715c-0.562,0.178-1.116,0.387-1.656,0.646L238.402,287.83L96.19,219.324
		c-0.539-0.259-1.093-0.468-1.654-0.646l-63.764-30.716L238.402,87.946z"/>
</g> */}
	{/* <g>
	<path
	 fill={fill}  stroke={fill} d="M326.86,353.255c0,7.281-34.683,28.345-100.874,28.345c-66.17,0-100.845-21.064-100.845-28.345v-73.347l-37.788-20.547
		v93.894c0,43.415,69.74,66.132,138.633,66.132c68.909,0,138.662-22.717,138.662-66.132v-93.894l-37.788,20.547V353.255z"/>
	<path
	 fill={fill}  stroke={fill} d="M455.31,374.32l-22.298-71.064c5.38-4.52,8.867-11.201,8.867-18.773c0-10.73-6.907-19.763-16.485-23.145V194.06
		l16.724-9.092c6.085-3.3,9.871-9.675,9.871-16.597c0-6.921-3.786-13.289-9.871-16.596L235.032,39.145
		c-5.641-3.068-12.428-3.068-18.056,0L9.87,151.775C3.786,155.083,0,161.45,0,168.371c0,6.922,3.786,13.297,9.87,16.605
		l207.106,112.607c2.813,1.527,5.919,2.291,9.024,2.291c3.105,0,6.21-0.764,9.024-2.298l174.071-94.649v58.41
		c-9.578,3.374-16.477,12.414-16.477,23.145c0,7.572,3.488,14.268,8.867,18.788l-22.29,71.05c-1.923,6.112-0.816,12.78,2.977,17.951
		c3.801,5.17,9.832,8.223,16.246,8.223h37.667c6.412,0,12.444-3.053,16.237-8.223C456.117,387.106,457.226,380.44,455.31,374.32z
		 M226.001,259.474L58.448,168.371l167.552-91.117l167.538,91.117L226.001,259.474z"/>
</g> */}