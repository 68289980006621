import React, { useState, useEffect } from 'react';

function HTMLDisplay({ url }) {
  const [htmlContent, setHtmlContent] = useState('');

  useEffect(() => {
    fetch(url)
      .then((response) => {
        if (response.ok) {
          return response.text();
        }
        throw new Error('Failed to fetch the HTML file.');
      })
      .then((data) => {
        setHtmlContent(data);
      })
      .catch((error) => {
        console.error("Error fetching the HTML content:", error);
      });
  }, [url]);

  return (
    <div dangerouslySetInnerHTML={{ __html: htmlContent }} />
  );
}

export default HTMLDisplay;