import React from 'react';

function TimeClock(props) {
	const fill = props.fill || 'currentColor';
	const secondaryfill = props.secondaryfill || fill;
	const title = props.title || "trash can";
	const classProps = props.classProps  || '';
	const strokeWidth = props.strokeWidth || '3';

	return (
		<svg viewBox="0 0 64 64" xmlns="http://www.w3.org/2000/svg">
	<g fill={secondaryfill} stroke={secondaryfill} strokeWidth={strokeWidth} strokeLinecap="square" strokeLinejoin="miter" strokeMiterlimit="10">
		<line fill="none" x1="32" x2="32" y1="3" y2="12"/>
		<line fill="none" x1="61" x2="52" y1="32" y2="32"/>
		<line fill="none" x1="32" x2="32" y1="61" y2="52"/>
		<line fill="none" x1="3" x2="12" y1="32" y2="32"/>
		<polyline fill="none" points="20 16 32 32 44 32"/>
		<circle cx="32" cy="32" fill="none" r="29" stroke={fill}/>
	</g>
</svg>
	);
};

export default TimeClock;