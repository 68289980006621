import HTMLDisplay from "./HTMLDisplay";

export default function Datenschutzerklaerung() {
  return (
    <div className="w-full">
      <section aria-labelledby="quick-links-title">
        <div className="rounded-lg bg-white overflow-hidden shadow divide-y">
          <div className='p-6'>
            
            <div className="mb-3 mt-6">
            <HTMLDisplay url="/assets/datenschutz.html" />
            </div> 
          </div>
        </div>
      </section>
    </div>
  );
}
