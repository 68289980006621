import React from 'react';

function Swap_horiz(props) {
	const fill = props.fill || 'currentColor';
	const secondaryfill = props.secondaryfill || fill;
	const title = props.title || "trash can";
	const classProps = props.classProps  || '';
	const strokeWidth = props.strokeWidth || '1';

	return (
		<svg  viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
	
	<g fill="none" >
		<path d="M6.99 11L3 15l3.99 4v-3H14v-2H6.99v-3zM21 9l-3.99-4v3H10v2h7.01v3L21 9z" stroke={fill} strokeWidth={strokeWidth}/>
	</g>
</svg>
	);
};

export default Swap_horiz;