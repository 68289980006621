import 'photoswipe/dist/photoswipe.css'
import 'react-responsive-carousel/lib/styles/carousel.min.css'
import './LocationDetail.css'

import { Fragment, useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { Menu, Popover, Transition } from '@headlessui/react'

import Marker from "./icons/marker"
import useStore from "../store";
import { formatDistanceToNow, parseISO, format, isThisSecond, getYear, getMonth, getDate, getHours,getMinutes, differenceInHours } from 'date-fns'
import { de } from 'date-fns/locale';
import { Gallery, Item } from 'react-photoswipe-gallery'
import { Carousel } from 'react-responsive-carousel';

const ics = require('ics')

const fallbackOriginalImg = '/img/default-img.png'
const fallbackThumbnailImg = '/img/default-img-thumb.png'

function classNames(...classes) {
  return classes.filter(Boolean).join(' ')
}

/** get width & height of image */
async function getImgMeta(url) {
  return new Promise((resolve) => {
    const img = new Image();
    img.src = url;
    img.onload = function() { 
      const width = this.width;
      const height = this.height;
      resolve({ width, height }); 
    }
    img.onerror = function() {
      resolve()
    }
  })
}

function download(filename, fileBody) {
  var element = document.createElement('a');
  element.setAttribute('href', 'data:text/plain;charset=utf-8,' + encodeURIComponent(fileBody));
  element.setAttribute('download', filename);

  element.style.display = 'none';
  document.body.appendChild(element);

  element.click();

  document.body.removeChild(element);
}

export default function Example(props) {
  const fetchEventById = useStore((state) => state.fetchEventById)
  const currentEvents = useStore((state) => state.currentEvents)

  const generateIcs = (eventInput) => {

    let duration = differenceInHours(new Date(eventInput.event_end), new Date(eventInput.event_start))

    if(duration > 48){
      duration = 48
    }
    if(duration < 1){
      duration = 1
    }

    console.log(duration,
      [
        getYear(new Date(eventInput.event_start)),
        getMonth(new Date(eventInput.event_start))+1,
        getDate(new Date(eventInput.event_start)),
        getHours(new Date(eventInput.event_start)),
        getMinutes(new Date(eventInput.event_start)),
      ])

    let event = {
      start: [
        getYear(new Date(eventInput.event_start)),
        getMonth(new Date(eventInput.event_start))+1,
        getDate(new Date(eventInput.event_start)),
        getHours(new Date(eventInput.event_start)),
        getMinutes(new Date(eventInput.event_start)),
      ],
      duration: { hours: duration },
      title: eventInput.name,
      description: eventInput.description,
      location: props.location.address,
      url: window.location.href,
      geo: { lat: props.location.latitude, lon: props.location.longitude },
      categories:  props.location.names,
      status: 'CONFIRMED',
      busyStatus: 'BUSY',
      organizer: { name: 'Berlin Recycling GmbH'},
    }
    ics.createEvent(event, (error, value) => {
      if (error) {
        console.log("ERROR: ", error)
        return
      }

      console.log(value)
      download(eventInput.name + '.ics', value);
    })
  }


  useEffect(() => {
  }, [])



  const localTags = useStore(state => state.localTags)

  const defaultSliderImgs = [{
    id: 1,
    src: props.location.location_image || fallbackOriginalImg,
    alt: "Slider 1",
    title: "Slider 1"
  }]

  const sliderImgs = props.location?.images && props.location.images.length ? 
    props.location.images.map((img, i) => ({ 
      id: i, 
      src: img.image || fallbackOriginalImg, 
      alt: `Slider ${i + 1}`, 
      title: `Slider ${i + 1}`
    })) : 
    defaultSliderImgs

  const defaultGalleryImgs = useMemo(() => {
    return [{
      id: 1,
      original: props.location.location_image || fallbackOriginalImg,
      thumbnail: props.location.location_image || fallbackThumbnailImg,
      alt: "Image 1",
      title: "Image 1",
      width: 1224,
      height: 816
    }]
  }, [props.location.location_image]) 

  const [galleryImgs, setGalleryImgs] = useState([])

  useEffect(() => {
    (async() => {
      if (props.location?.images && props.location.images.length) {
        const promises = props.location.images.map(async (img, i) => {
          const imgMeta = await getImgMeta(img.image)

          return ({
            id: i, 
            original: img.image || fallbackOriginalImg, 
            thumbnail: img.image || fallbackOriginalImg,
            alt: `Image ${i + 1}`, 
            title: `Image ${i + 1}`,
            width: imgMeta?.width || 1224,
            height: imgMeta?.height || 816
          })
        })
        const imgs = await Promise.all(promises)
        setGalleryImgs(imgs)
      } else {
        setGalleryImgs(defaultGalleryImgs)
      }
    })()
  }, [defaultGalleryImgs, props.location.images])

  // must reuse same image element, otherwise it will fetching new image and cause blinking every time open the gallery image
  const cachedFallbackGalleryImgs = useRef({})

  const handleBeforeOpenGallery = useCallback((instance) => {
    instance.addFilter('contentErrorElement', (contentErrorElement, content) => {
      // get from cache if already loaded
      if (cachedFallbackGalleryImgs.current[content.index]) {
        return cachedFallbackGalleryImgs.current[content.index]
      }
      
      // create img instance
      const el = document.createElement('img')
      el.src = fallbackOriginalImg
      el.width = content.displayedImageWidth
      el.height = content.displayedImageHeight

      // save to cache
      cachedFallbackGalleryImgs.current[content.index] = el

      return el;
    });
  }, [])

  return (
    <>
      <span className='hidden border-lime-500 text-lime-500 text-trennt_gray border-trennt_gray text-trennt_green border-trennt_green border-trennt_brown text-trennt_brown text-trennt_blue border-trennt_blue text-trennt_turcouse border-trennt_turcouse text-trennt_yellow border-trennt_yellow'></span>

      <div className="min-h-full w-full">
          <Carousel
            className='location-detail-carousel h-[400px] w-full'
            swipeable 
            emulateTouch 
            infiniteLoop 
            showStatus={false}
          >
            {sliderImgs.map(image => (
              <div 
                as="header" 
                className="pb-24 w-full h-full bg-cover bg-center bg-opacity-40" 
                style={{
                  backgroundImage: `url("${image.src}")`
                }}>
                {/** fallback logic if image downloaded invalid/error, 
                 * reference: https://stackoverflow.com/a/62714809/10661914 
                 * extra wrapper needed because of conflict with style injected on img by carousel
                 */}
                <div className='hidden'> 
                  <img
                    src={image.src}
                    alt='img'
                    onError={(e) => {
                      e.currentTarget.parentElement.parentElement.style.backgroundImage = `url(${fallbackOriginalImg})`
                    }}
                  />
                </div>
              </div>
            ))}
          </Carousel>
        <main className="-mt-12 pb-8 relative">
          <div className="max-w-3xl mx-auto px-4 sm:px-6 lg:max-w-6xl lg:px-8">
            <h1 className="sr-only">Profile</h1>
            {/* Main 3 column grid */}
            <div className="grid grid-cols-1 gap-4 items-start lg:gap-8 relative z-10">
              {/* Left column */}
              <div className="grid grid-cols-1 gap-4">
                {/* Welcome panel */}
                <section aria-labelledby="profile-overview-title">
                  <div className="rounded-lg bg-white overflow-hidden shadow">
                    <h2 className="sr-only" id="profile-overview-title">
                      Profile Overview
                    </h2>
                    <div className="bg-white p-6">
                      <div className="sm:flex sm:items-center sm:justify-between">
                        <div className="sm:flex sm:space-x-5">
                          <div className="my-auto">
                            <div className="w-10 h-10 stroke-1 mx-auto">
                              <Marker></Marker>
                            </div>
                            
                          </div>
                          <div className="mt-4 text-center sm:mt-0 sm:pt-1 sm:text-left">
                            {/* <p className="text-sm font-medium text-gray-600">Welcome back,</p> */}
                            <p className="text-xl font-bold text-gray-900 sm:text-2xl">{props.location.name}</p>
                            <p className="text-sm font-medium text-gray-600">{props.location.address}</p>
                            <div className='pt-2 flex spaye-x-4 justify-center sm:justify-start'>
                            <a className='hover:opacity-50' href={"https://maps.google.com/?q=Berlin "+ props.location.address + " " + props.location.name} target="_self" rel="noreferrer">
                            <img className='w-6 h-6' src='/img/Google_Maps_icon_(2015-2020).svg.png'></img>
                            </a>
                            {(props.location.web_site) && (
                        
                            <a className='hover:opacity-50' href={props.location.web_site} target="_self" rel="noreferrer">
                            <img className='ml-3 w-6 h-6' src='/img/web-icon-logo-A6B586D114-seeklogo.com.png'></img>
                            </a>
                            )}
                            </div>
                          </div>
                        </div>
                        <div className='flex shrink-0 justify-center sm:justify-start'>
                        <div className="mt-5 flex justify-center sm:mt-0 shrink-0">
                          <a
                            href={"mailto:info@berlin-recycling.de?subject=Änderungsvorschlag für: " + props.location.name + "(" + window.location.href + ")&body=Bitte geben sie Ihren Änderungsvorschlag hier an:"}
                            className="flex justify-center items-center px-4 py-2 border border-gray-300 shadow-sm text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50"
                          >
                            Änderung vorschlagen
                          </a>
                        </div>

                        </div>
                      </div>
                    </div>
                    <div className="border-t border-gray-200 bg-gray-50 flex divide-y divide-gray-200 sm:divide-y-0 sm:divide-x justify-center flex-wrap sm:justify-start">
                      {props.location?.tags?.map((tag, index) => {
                        let localTag = localTags.find((localTag) => localTag.name === tag.name)
                        if(localTag) {
                        return (
                          <div key={index} className={"w-full sm:w-auto py-2 px-6 my-auto text-center flex "

                            + localTag.color + ' opacity-100 stroke-'
                            + localTag.color + ' text-'
                            + localTag.color + ' fill-'
                            + localTag.color + " stroke-[4px]"}
                          >
                            <div className='w-6 h-6 my-auto'>
                              {localTag.icon}
                            </div>
                            <div key={tag.id} className="pl-3 py-5 text-sm font-medium text-center">
                              <span className={"text-" + localTag.color}>{tag.name}</span>
                            </div>
                          </div>
                        )
                        }
                      }
                      )}


                    </div>
                  </div>
                </section>

                {/* Actions panel */}
                <section aria-labelledby="quick-links-title">
                  <div className="rounded-lg bg-white overflow-hidden shadow divide-y ">
                    <div className='p-6'>
                      <h3 className='text-xl font-bold'>Beschreibung</h3>
                      <p className="mt-1 text-md text-gray-600 whitespace-pre-wrap">{props.location.description}</p>
                      {(props.location.web_site) && (
                        <p className='mt-4 text-md text-gray-600 whitespace-pre-wrap'>Mehr Informationen zu diesem Ort finden sie auf: <a className='text-blue-500' href={props.location.web_site} target="_self" rel="noreferrer">{props.location.web_site}</a></p>
                      )}
                    </div>
                    {(currentEvents.length > 0 ) && (
                    <div className="p-6">
                    <h3 className='text-xl font-bold'>Nächsten Veranstaltungen</h3>
                      <div className="flow-root mt-6">
                        <ul role="list" className="-my-5 divide-y divide-gray-400">
                          {currentEvents.map((event) => (
                            <li key={event.id} className="py-5">
                              <div className="relative">
                                <h3 className="text-lg font-semibold text-gray-800">

                                    {event.name}

                                </h3>
                                <div className='w-full'>
                                <p className="mt-1 text-md text-gray-600">Start: <b>{format(new Date(event.event_start), 'dd.MM.yyyy  HH:mm')}</b> </p>
                                <p className="mt-1 text-md text-gray-600 ">Ende: <b>{format(new Date(event.event_end), 'dd.MM.yyyy  HH:mm')}</b></p>
                                </div>
                                <p className="mt-1 text-md text-gray-600  whitespace-pre-wrap">{event.description}</p>

                              </div>
                              <div
                                onClick={() => {generateIcs(event)}}
                                className="cursor-pointer mt-5 w-48 flex justify-center items-center px-4 py-2 border border-prime-900 shadow-sm text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50"
                              >
                                Im Kalender eintragen
                              </div>
                            </li>
                          ))}
                        </ul>
                      </div>
                      <div className="mt-6">

                      </div>
                    </div>
                    )}
                  </div>

                </section>

                <section aria-labelledby="gallery" className='max-w-screen overflow-x-auto'>
                  <div className="flex gap-5 overflow-x-auto">
                    {galleryImgs.length ? <Gallery onBeforeOpen={handleBeforeOpenGallery}>
                      {galleryImgs.map(image => (
                        <Item
                          original={image.original}
                          thumbnail={image.thumbnail}
                          width={image.width}
                          height={image.height}
                        >
                          {({ ref, open }) => (
                            <div 
                              className="cursor-pointer flex-grow-0 flex-shrink-0 h-24 w-24 bg-gray-200 rounded-lg bg-cover bg-center bg-no-repeat"
                              ref={ref} 
                              onClick={open}
                              style={{
                                backgroundImage:
                                  `url("${image.thumbnail}")`,
                              }}
                            >
                              {/** 
                               * fallback logic if image downloaded invalid/error, 
                               * reference: https://stackoverflow.com/a/62714809/10661914 
                               */}
                              <img
                                className='hidden'
                                src={image.thumbnail}
                                alt='img'
                                onError={(e) => {
                                  e.currentTarget.parentElement.style.backgroundImage = `url(${fallbackThumbnailImg})`
                                }}
                              />
                            </div>
                          )}
                        </Item>
                      ))}
                    </Gallery> : null}
                  </div>
                </section>

              </div>

              {/* Right column */}
              <div className="grid grid-cols-1 gap-4">
                {/* Announcements */}
                <section aria-labelledby="announcements-title">
                  <div className="rounded-lg bg-white overflow-hidden shadow">

                  </div>
                </section>

              </div>
            </div>
          </div>
        </main>

      </div>
    </>
  )
}