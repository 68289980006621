import React from 'react'
import { Fragment, useState } from 'react'

import LocationEntry from '../components/LocationEntry'
import { useEffect } from 'react'
import useStore from '../store'


export default function EntryList() {
    const locations = useStore((state) => state.locations)
    const filterdLocations = useStore((state) => state.filterdLocations)
    const [isShown, setIsShown] = useState(false);
    const activeTags = useStore((state) => state.activeTags)



  return (
    <div className='mt-8 md:mt-24 grid gap-5 grid-cols-1 px-5 z-0'>
      {isShown && (
        <div>
          I'll appear when you hover over the button.
        </div>
      )}
      {filterdLocations.map((location, index) => (
        <>
        <LocationEntry location={location}></LocationEntry>
        {/* Check if location has tag metching name in active tag  */}

        </>
      ))}
    </div>
  )
}
