

import create from 'zustand'
import {devtools} from 'zustand/middleware'

import locationStore from './locationStore'
// import projectStore from './projectStore'
//https://www.youtube.com/watch?v=jLcF0Az1nx8

// let usePersistantStore = persist(useStore)

let useStore = create(devtools((set, get, api) => ({
  ...locationStore(set, get, api),
  // projectStore: {...projectStore(set, get, api)},
  })))

export default useStore
