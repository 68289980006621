import React from "react";

import {
  Routes,
  Route,
  useLocation
} from 'react-router-dom';

import Home from './pages/Home';
import LocationDetailPage from './pages/LocationDetailPage';
import PageNotFound from './pages/PageNotFound';
import Impressum from "./pages/Impressum";
import Datenschutzerklaerung from "./pages/Datenschutzerklaerung";

//import axios from 'axios';

function App() {
  return (
    <Routes>
      <Route path="/" element={<Home />} />
      <Route path="location/:id/:name" element={<LocationDetailPage />} />
      <Route path="impressum" element={<Impressum />} />
      <Route path="datenschutzerklaerung" element={<Datenschutzerklaerung />} />
      <Route path="*" element={<PageNotFound />} />
    </Routes>
  );
}

export default App;
