import React from 'react'
import CutText from '../components/CutText'
import { Link } from 'react-router-dom';
import Calender from '../components/icons/calendar-2'
import useStore from "../store";
// onMouseEnter={() => this.someHandler}
// onMouseLeave={() => this.someOtherHandler}
import { de } from 'date-fns/locale';

import { formatDistanceToNow, parseISO, format, isThisSecond } from 'date-fns'

export default function Entry(props) {
  const locationOnHover = useStore((state) => state.locationOnHover)
  const locationOnClick = useStore((state) => state.locationOnClick)
  const setLocationOnHover = useStore((state) => state.setLocationOnHover)
  const setLocationOnClick = useStore((state) => state.setLocationOnClick)
  const localTags = useStore((state) => state.localTags)
  const lol = "/img/default-img.png"
  const bgImg = props.location?.location_image || lol

  const handelLocationOnClick = (location) => {
    setLocationOnHover(location);
    setLocationOnClick(location);
  }

  const handelHoverOnLocation = (state) => {
    if(state != locationOnClick || state != null){
    if (state) {
      setLocationOnHover(props.location)
    } else {
      setLocationOnHover(null)
    }
  }
  }
  function removeTrailingSlash(str) {
    return str.replace(/\//, '');
  }

  const getFormattedDate = (date) => {
    let dt = parseISO(date)
    console.log(dt)
    let distance = formatDistanceToNow(new Date(date), { locale: de })
    return distance
  }


  return (
    <>
      <span className='hidden border-lime-500 text-lime-500 text-trennt_gray border-trennt_gray text-trennt_green border-trennt_green border-trennt_brown text-trennt_brown text-trennt_blue border-trennt_blue text-trennt_turcouse border-trennt_turcouse text-trennt_yellow border-trennt_yellow'></span>
      
        <div
          className={'flex flex-col shadow-lg cursor-pointer transform-gpu transition ease-in-out hover:shadow-xl h-full scroll-mt-64 hover:bg-prime-100 '
            + ((props.location === locationOnHover) ? ' bg-prime-100 ' : 'bg-white ')
            + ((props.location === locationOnClick) && ' border-4 border-prime-500')}

          onMouseEnter={() => handelHoverOnLocation(props.location)}
          onMouseLeave={() => handelHoverOnLocation(null)}
          onClick={() => handelLocationOnClick(props.location)}
          id={"location-" + props.location.id}>

          <div className="flex flex-col xl:flex-row">
            <div className="cursor-pointer xl:w-[400px] h-[200px] xl:h-full">
              <div className={"bg-black h-full w-full bg-cover bg-center"} style={{ 
                backgroundImage: `url(${bgImg})`
              }}>
                {/** fallback logic if image downloaded invalid/error, reference: https://stackoverflow.com/a/62714809/10661914 */}
                <img
                  className='hidden'
                  src={bgImg}
                  alt='img'
                  onError={(e) => {
                    e.currentTarget.parentElement.style.backgroundImage = `url(${lol})`
                  }}
                />
              </div>
            </div>
            
            <div className="p-6 w-full">
              <div className="block mt-2">
                <div className='flex justify-between flex-wrap'>
                  <p className="text-xl font-semibold text-gray-700 ">{props.location.name}</p>

                  {/* map props.location.events */}


                </div>


                <div className="mt-2 text-base text-gray-500"><CutText limit={200} content={props.location.description} noReadeMore={true}></CutText></div>
                {(props.location.events[0]?.event_start) && (
                  <div className='flex mt-2 '>
                    <svg xmlns="http://www.w3.org/2000/svg" class=" h-[18px] w-[18px] text-black " fill="none" viewBox="0 0 24 24" stroke="currentColor" stroke-width="1">
                      <path stroke-linecap="round" stroke-linejoin="round" d="M8 7V3m8 4V3m-9 8h10M5 21h14a2 2 0 002-2V7a2 2 0 00-2-2H5a2 2 0 00-2 2v12a2 2 0 002 2z" />
                    </svg>
                    <small className='text-black text-right ml-1'><b>{getFormattedDate(props.location.events[0]?.event_start)}</b> bis zur nächsten Veranstaltung</small>
                  </div>
                )}
                <div className='pt-2 flex space-x-2 justify-between '>
                  <div className="text-sm font-medium text-prime-600 flex">
                    <div
                      className="text-md  inline-flex items-center leading-sm  py-1  text-prime-900 space-x-4"
                    >
                      {/* Display all tags in the location with the corrent icons from localTags. */}
                      {props.location.tags.map((tag, index) => {
                        let localTag = localTags.find((localTag) => localTag.name === tag.name)
                        if (localTag) {
                          return (
                            <div key={index} className={"pt-2 py-2 pb-1 my-auto text-center cursor-pointer items-center justify-center  " +

                              + localTag.color + ' bg-'
                              + localTag.color + 'opacity-10 stroke-'
                              + localTag.color + ' text-'
                              + localTag.color + ' fill-'
                              + localTag.color + " "}
                            >
                              <div className='w-6 h-6 stroke-[4px]'>
                                {/* Search for element in localTags and get the icon */}

                                <>
                                  {localTag.icon}
                                </>

                              </div>
                            </div>
                          )
                        }
                      }
                      )}
                    </div>
                  </div>
                  {(props.location === locationOnHover || props.location === locationOnClick) && (
                    <Link
                    target="_self"
                    className="text-sm font-medium text-white px-4 my-1 flex bg-prime-900 hover:bg-prime-700 rounded-md"
                    to={"/location/" + props.location.id + "/" + removeTrailingSlash(props.location.name.split(' ').join('_'))}
                  >
                    <div className='my-auto '>Details anzeigen </div>

                  </Link>
                  )}

                </div>
              </div>
            </div>
          </div>
        </div>
    </>
  )
}
