import React from 'react'
import { Fragment, useState } from 'react'
import Calendar2 from "./icons/calendar-2"
import Marker from "./icons/marker"
import { useEffect } from 'react'
import useStore from "../store";
import { MagnifyingGlassIcon } from '@heroicons/react/24/outline'

export default function LocationEventSwitch() {
    const showSearchBar = useStore(state => state.showSearchBar)
    const toogleSearchBar = useStore(state => state.toogleSearchBar)
    // const [showLocations, setShowLocations] = useState(true);
    // const [showEvents, setShowEvents] = useState(true);
    // const showEvents = useStore(state => state.showEvents)
    // const setShowEvents = useStore((state) => state.setShowEvents)
    // const showLocations = useStore(state => state.showLocations)
    // const setShowLocations = useStore((state) => state.setShowLocations)

    // const handelSwitch = (key) => {
    //     if (key === "locations") {
    //         setShowLocations();
    //     } else {
    //         setShowEvents();
    //     }
    // }

    // useEffect(() => {
    //     if(!showLocations) {
    //         if(!showEvents) {
    //             setShowEvents();
    //         }
    //     }
    // }, [showLocations])

    // useEffect(() => {
    //     if(!showEvents) {
    //         if(!showLocations) {
    //             setShowLocations();
    //         }
    //     }
    // }, [showEvents])

    return (
        <>
        <div className={`md:bg-prime-800 md:hover:bg-prime-700 rounded-full p-2 cursor-pointer`} onClick={toogleSearchBar}>
        <MagnifyingGlassIcon  className="mx-auto h-6 w-6 text-black md:text-white stroke-[2px]" aria-hidden="true"></MagnifyingGlassIcon>
        </div>
            {/* <div className=' rounded-full flex divide-x-2 divide-gray-600 overflow-hidden'>
                <div className={'py-0.5 px-4 cursor-pointer  ' + (showEvents ? 'bg-gray-200' : 'bg-gray-100')}
                    onClick={() => handelSwitch("calender")}>
                    <div className={"text-md  inline-flex items-center leading-sm  py-1 " + (showEvents ? 'text-prime-900' : 'text-gray-400')}>
                        <div className="w-5 h-5 stroke-[7px]">
                            <Calendar2></Calendar2>
                        </div>
                    </div>
                </div>
                <div className={'py-0.5 px-4 cursor-pointer  ' + (showLocations ? 'bg-gray-200' : 'bg-gray-100')} onClick={() => handelSwitch("locations")}>
                    <div className={"text-md  inline-flex items-center leading-sm  py-1 " + (showLocations ? 'text-prime-900' : 'text-gray-400')}>
                        <div className="w-5 h-5 stroke-[2px]">
                            <Marker></Marker>
                        </div>
                    </div>
                </div>
            </div> */}
        </>
    )
}
