import React from 'react';

function ShoppingBag(props) {
	const fill = props.fill || 'currentColor';
	const secondaryfill = props.secondaryfill || fill;
	const title = props.title || "trash can";
	const classProps = props.classProps  || '';
	const strokeWidth = props.strokeWidth || '3';

	return (
		<svg viewBox="0 0 64 64" xmlns="http://www.w3.org/2000/svg">
	
	<g fill={secondaryfill} stroke={secondaryfill} strokeLinecap="square" strokeLinejoin="miter" strokeMiterlimit="10" >
		<path d="M5.945,55.2,12,18H52l6.055,37.2A5,5,0,0,1,53.12,61H10.88A5,5,0,0,1,5.945,55.2Z" fill="none" stroke={secondaryfill}/>
		<path d="M22,28V13A10,10,0,0,1,32,3h0A10,10,0,0,1,42,13V28" fill="none"/>
	</g>
</svg>
	);
};

export default ShoppingBag;