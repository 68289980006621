export default function Example() {
    return (
      <>

        {/*
          This example requires updating your template:

          ```
          <html class="h-full">
          <body class="h-full">
          ```
        */}
        <main
          className="min-h-screen bg-cover bg-center bg-no-repeat "
          style={{
            backgroundImage:
              'url("/img/trash.jpeg")',
          }}
        >
          <div className="w-full bg-opacity-70 bg-black">
          <div className="max-w-7xl mx-auto px-4 py-16 text-center sm:px-6 sm:py-24 lg:px-8 lg:py-48 h-screen ">
            <p className="text-sm font-semibold text-white  uppercase tracking-wide">404 Fehler</p>
            <h1 className="mt-2 text-4xl font-extrabold text-white tracking-tight sm:text-5xl">
              Oh oh! Ich glaube, du bist verloren.
            </h1>
            <p className="mt-2 text-lg font-medium text-white ">
            Anscheinend existiert die gesuchte Seite nicht.
            </p>
            <div className="mt-6">
              <a
                href="/"
                className="inline-flex items-center px-4 py-2 border border-transparent text-sm font-medium rounded-md text-prime-500 bg-prime-100 bg-opacity-75 sm:bg-opacity-25 sm:hover:bg-opacity-50"
              >
                Zurück zur Homepage
              </a>
            </div>
          </div>
          </div>
        </main>

      </>
    )
  }