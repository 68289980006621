import React from 'react'
import { Fragment, useState, useRef } from 'react'
import { useEffect } from 'react'
import Map, { Marker } from 'react-map-gl';
import mapboxgl from "mapbox-gl";

import { MapRef } from 'react-map-gl';
import MarkerIconOnMap from './icons/markerForMap';
import 'mapbox-gl/dist/mapbox-gl.css';
import useStore from '../store';
import { Link } from 'react-router-dom';
import { HashLink } from 'react-router-hash-link';

mapboxgl.workerClass = require('worker-loader!mapbox-gl/dist/mapbox-gl-csp-worker').default; // eslint-disable-line

export default function MapWithWrapper(props) {
  const locations = useStore((state) => state.filterdLocations)
  const locationOnHover = useStore((state) => state.locationOnHover)
  const locationOnClick = useStore((state) => state.locationOnClick)

  const setLocationOnHover = useStore((state) => state.setLocationOnHover)
  const [isShown, setIsShown] = useState(false);
  const [lng, setLng] = useState(13.404954);
  const [lat, setLat] = useState(52.520007);
  const [zoom, setZoom] = useState(11);


  const mapRef = useRef();

  const handelHoverOnLocation = (location) => {
    setLocationOnHover(location);
  }

  useEffect(() => {
    if (locationOnClick?.longitude) {
      mapRef?.current?.flyTo({ center: [locationOnClick.longitude, locationOnClick.latitude], duration: 1000, zoom: 11 })
    }
  }, [locationOnClick])

  // useEffect(() => {
  //   if (locations[0]?.longitude) {
  //     mapRef?.current?.flyTo({ center: [locations[0].longitude, locations[0].latitude], duration: 2000 })
  //   }
  // }, [locations])


  return (
    <Map
      initialViewState={{
        longitude: lng,
        latitude: lat,
        zoom: zoom,
      }}
      ref={mapRef}
      showZoom={true}
      mapStyle="mapbox://styles/mapbox/streets-v11"
      mapboxAccessToken="pk.eyJ1IjoiYnIyMDIxIiwiYSI6ImNsNDQzZ3l6NTAwNmEzbHFtZmRtNnlyMmsifQ.VKGyjhyNnZikMTg89kyiaA"
      style={{ width: '100%', height: '100%' }}
    >
      {locations.map((location, index) => (

        <HashLink to={"#location-" + location.id} key={index}>
          <Marker
            longitude={location.longitude}
            latitude={location.latitude}
            anchor="bottom"
            style={{ cursor: "pointer" }}
            onClick={() => {
              handelHoverOnLocation(location)
              props.onClickMarker?.()
            }}
          >
            <div className={"w-9 h-9 stroke-[7px]  " 
            + ((location === locationOnHover) ? 'text-prime-900 fill-' : 'text-gray-400')
            + ((location === locationOnClick) && ' animate-bounce ')}>
              <MarkerIconOnMap
                classProps=""
                location={location}
                isActive={location === locationOnHover}
                fill={((location === locationOnHover) ? ' #000000' : '#e37c44')}
                secondaryfill={((location === locationOnHover) ? '#fd7226' : '#ffffff')}
                fillOpacity={1}></MarkerIconOnMap>
            </div>
            
          </Marker>
        </HashLink>
      ))}

    </Map>
  )
}
