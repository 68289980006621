import { Fragment, useEffect, useRef, useState } from 'react'
import { MapPinIcon } from '@heroicons/react/24/outline'
import { Combobox, Dialog, Transition } from '@headlessui/react'
import useStore from '../store'
import { HashLink } from 'react-router-hash-link';

const people = [
  { id: 1, name: 'Leslie Alexander', url: 'hello' },
  // More people...
]

function classNames(...classes) {
  return classes.filter(Boolean).join(' ')
}

export default function SearchBar(props) {
  const showSearchBar = useStore(state => state.showSearchBar || false)
  const toogleSearchBar = useStore(state => state.toogleSearchBar)
  const locations = useStore(state => state.locations)
  const filteredLocations = useStore(state => state.filterdLocations)
  const locationOnClick = useStore(state => state.locationOnClick)
  const setLocationOnClick = useStore(state => state.setLocationOnClick)
  const setLocationOnHover = useStore(state => state.setLocationOnHover)
  const [query, setQuery] = useState('')
  const setActiveTags = useStore((state) => state.setActiveTags)
  const isNeedScroll = useRef(false);

  const filteredPeople =
    query === ''
      ? []
      : locations.filter((location) => {
          const locationInfo = `${location.name} ${location.description}`
          const normalizedQuery = query.toLowerCase()
          return locationInfo.toLowerCase().includes(normalizedQuery)
          // return location.name.toLowerCase().includes(normalizedQuery)
        })

  const handleChange = (location) => {
    setLocationOnClick(location)
    setLocationOnHover(location)
    setActiveTags(location.tags)
    toogleSearchBar()
    isNeedScroll.current = true
  }

  /**
   * this effect will add hash to url after state changes
   * needed to support scrolling after pressing enter on search result
   */
  useEffect(() => {
    if (locationOnClick && !showSearchBar && isNeedScroll.current) {
      isNeedScroll.current = false
      window.location.hash = '#!'
      setTimeout(() => {
        window.location.hash = '#location-' + locationOnClick.id;
      }, 100)
    }
  }, [showSearchBar, locationOnClick])

  return (
    <Transition.Root show={showSearchBar} as={Fragment} afterLeave={() => setQuery('')} appear>
      <Dialog as="div" className="relative z-50" onClose={toogleSearchBar}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-500 bg-opacity-25 transition-opacity" />
        </Transition.Child>

        <div className="fixed inset-0 z-10 overflow-y-auto p-4 sm:p-6 md:p-20">
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0 scale-95"
            enterTo="opacity-100 scale-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 scale-100"
            leaveTo="opacity-0 scale-95"
          >
            <Dialog.Panel className="mx-auto max-w-xl transform rounded-xl bg-white p-2 shadow-2xl ring-1 ring-black ring-opacity-5 transition-all">
              <Combobox onChange={(location) => (handleChange(location))}>
                <Combobox.Input
                  className="w-full rounded-md border-0 bg-gray-100 px-4 py-2.5 text-gray-900 placeholder-gray-500 focus:ring-0 sm:text-sm"
                  placeholder="Nach Orten suchen..."
                  onChange={(event) => setQuery(event.target.value)}
                />

                {filteredPeople.length > 0 && (
                  <Combobox.Options
                    static
                    className="-mb-2 max-h-72 scroll-py-2 overflow-y-auto py-2 text-sm text-gray-800"
                  >
                    {filteredPeople.map((location) => (
                      <HashLink to={"#location-" + location.id} key={location.id}>
                      <Combobox.Option
                        value={location}
                        className={({ active }) =>
                          classNames(
                            'cursor-pointer select-none rounded-md px-4 py-2',
                            active && 'bg-prime-600 text-white'
                          )
                        }
                      >
                        {location.name}
                      </Combobox.Option>
                      </HashLink>
                    ))}
                  </Combobox.Options>
                )}

                {query !== '' && filteredPeople.length === 0 && (
                  <div className="py-14 px-4 text-center sm:px-14">
                    <MapPinIcon className="mx-auto h-6 w-6 text-gray-400" aria-hidden="true" />
                    <p className="mt-4 text-sm text-gray-900">Leider konnten wir keinen Ort finden...</p>
                  </div>
                )}
              </Combobox>
            </Dialog.Panel>
          </Transition.Child>
        </div>
      </Dialog>
    </Transition.Root>
  )
}