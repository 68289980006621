import React from 'react';

function Savings(props) {
	const fill = props.fill || 'currentColor';
	const secondaryfill = props.secondaryfill || fill;
	const title = props.title || "trash can";
	const classProps = props.classProps  || '';
	const strokeWidth = props.strokeWidth || '3';
	return (
		<svg className={classProps} viewBox="0 0 64 64" xmlns="http://www.w3.org/2000/svg">
	<g  stroke={fill} fill={fill}  strokeLinecap="square" strokeLinejoin="miter" strokeMiterlimit="10">
		<path d="M37.441,32a15.937,15.937,0,0,0,21.118,0A15.983,15.983,0,0,0,32,44v2" fill="none"  stroke={fill} strokeLinecap="butt"/>
		<path d="M32,46V44A15.983,15.983,0,0,0,5.441,32a15.937,15.937,0,0,0,21.118,0" fill="none"  stroke={fill} strokeLinecap="butt"/>
		<circle cx="32" cy="14" fill="none" r="12"/>
		<polygon fill="none" points="20 46 44 46 42 62 22 62 20 46"  stroke={fill}/>
		<rect height="7.071" width="7.071" fill="none" transform="translate(-0.527 26.728) rotate(-45)" x="28.464" y="10.464"/>
	</g>
</svg>
	);
};

export default Savings;