import produce from 'immer'
import axios from 'axios'
import TrashCan from "../components/icons/recycle-2"
import Settings from "../components/icons/settings"
import ShoppingBag from "../components/icons/shopping-bag"
import Swap from "../components/icons/swap_horiz"
import Present from "../components/icons/present"
import TimeClock from "../components/icons/time-clock"
import Restaurant from '../components/icons/restaurant'
import Upcyceln from '../components/icons/savings'
import Kleidung from '../components/icons/checkroom'
import School from '../components/icons/school'

let locationStore = (set, get, api) => ({
  locations: [],
  filterdLocations: [],
  tags: [],
  showEvents: true,
  showLocations: true,
  activeTags: [],
  currentLocation: {},
  currentEvents: [],
  locationOnHover: {},
  setLocationOnHover(location) {
    set(state => produce(state, draft => {
      draft.locationOnHover = location
    }
    ))
  },
  setLocationOnClick(location) {
    set(state => produce(state, draft => {
      draft.locationOnClick = location
    }
    ))
  },
  toogleSearchBar () {
    set(state => produce(state, draft => {
      draft.showSearchBar = !state.showSearchBar
    }
    ))
  },

  setShowEvents() {
    set(state => produce(state, draft => {
      draft.showEvents = !draft.showEvents
    }
    ))
    get().updateFilterdLocations()
  },
  setShowLocations() {
    set(state => produce(state, draft => {
      draft.showLocations = !draft.showLocations
    }
    ))
    get().updateFilterdLocations()
  },
  addActiveTag: async (tagName) => {
    await set(state => produce(state, draft => {
      // find tag in state.tags with tagName as name

      let tag = draft.tags.find(tag => tag.name === tagName)
      console.log(tag)
      // if tag is not in state.activeTags, add it
      if (!draft.activeTags.find(activeTag => activeTag.name === tagName)) {
        draft.activeTags.push(tag)
      }
    }))
    get().updateFilterdLocations()
  },
  removeActiveTag: async (tagName) => {
    await set(state => produce(state, draft => {
      // find tag in state.activeTags with tagName as name
      let activeTag = draft.activeTags.find(activeTag => activeTag.name === tagName)
      // if tag is in state.activeTags, remove it
      if (activeTag) {
        draft.activeTags.splice(draft.activeTags.indexOf(activeTag), 1)
      }
    }))
    get().updateFilterdLocations()
  },
  clearActiveTags: () => {
    set(state => produce(state, draft => {
      draft.activeTags = []
    }))
    get().updateFilterdLocations()
  },
  // replace tags
  setActiveTags: (tags) => {
    set(state => produce(state, draft => {
      draft.activeTags = tags
    }))
    get().updateFilterdLocations()
  },

  updateFilterdLocations: async () => {
    let locations = get().locations

    if (get().activeTags.length !== 0) {
      locations = locations.filter(location => {
        let tags = location.tags.map(tag => tag.name)
        let activeTags = get().activeTags.map(tag => tag.name)
        return tags.some(tag => activeTags.includes(tag))
      })
    }

    if (get().showEvents === true && get().showLocations === false ) {
      locations = locations.filter(location => location.events.length > 0)
    }

    if (get().showEvents === false && get().showLocations === true) {
      locations = locations.filter(location => !location.is_event_location_only)
    }

    set(state => produce(state, draft => {
      draft.filterdLocations = locations
    }
    ))
  },

  // fetch all events from

  fetchLocations: async () => {
    console.log('fetchLocations')
    const response = await axios.get('https://www.sero-karte.de/api/locations/')
    set(produce(state => {
      state.locations = response.data
    }))
    return response.data
  },

  fetchLocationById: async (id) => {
    console.log('fetchLocationsById')
    const response = await axios.get('https://www.sero-karte.de/api/locations/' + id)
    set(produce(state => {
      state.currentLocation = response.data
    }))
    return response.data
  },

  fetchEventById: async (id) => {
    console.log('fetchEventById')
    const response = await axios.get('https://www.sero-karte.de/api/events/' + id)
    //add them to currentEvents array in store

    set(produce(state => {
      state.currentEvents.push(response.data)
    }
    ))
    return response.data

  },

  fetchTags: async () => {
    console.log('fetchTags')
    const response = await axios.get('https://www.sero-karte.de/api/tags/')
    console.log(response.data)

    await set(produce(state => {
      state.tags = response.data
    }))
    // await set(produce(state => {
    //   state.activeTags.push(state.tags.find(tag => tag.name === 'Recyceln'))
    // }))

    get().updateFilterdLocations()
    return response.data
  },
  localTags: [
    {
      id: 2,
      name: "Recyceln",
      icon: <TrashCan />,
      color: "prime-900",
    },
    {
      id: 2,
      name: "Schenken",
      icon: <Present />,
      color: "trennt_green",
    },
    {
      id: 2,
      name: "Essen",
      icon: <Restaurant />,
      color: "lime-500",
    },
    {
      id: 2,
      name: "Kaufen",
      icon: <ShoppingBag />,
      color: "trennt_yellow",
    },
    {
      id: 2,
      name: "Kleidung",
      icon: <Kleidung />,
      color: "fuchsia-500"
    },
    {
      id: 2,
      name: "Reparieren",
      icon: <Settings />,
      color: "trennt_turcouse",
    },
    {
      id: 2,
      name: "Leihen",
      icon: <TimeClock />,
      color: "trennt_blue",
    },
    {
      id: 2,
      name: "Tauschen",
      icon: <Swap />,
      color: "trennt_brown",
    },
    {
      id: 2,
      name: "Upcyceln",
      icon: <Upcyceln />,
      color: "indigo-500",
    },
    {
      id: 2,
      name: "Lernen",
      icon: <School />,
      color: "rose-700"
    }
  ],
})
export default locationStore